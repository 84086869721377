import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Modal } from "./Modal";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { Spinner } from "./Spinner";
import { callAPI } from "./CallAPI";

export const returnReasons = [
  "I don't like it",
  "It doesn't fit",
  "It's defective",
  "It's not what I expected",
  "I changed my mind",
  "Other (item in perfect condition)",
  "Other (item NOT in perfect condition)",
];

export const Products = () => {
  const [products, setProducts] = useState([
    {
      id: 1,
      product_photo:
        "https://images.unsplash.com/photo-1626947346165-4c2288dadc2a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=320&q=80",
      brand_name: "",
      product_name: "Clarks shoes",
      color: "Brown",
      price: "75,00",
      size: "46",
      reason: "",
      path: "javascript:void(0)",
      isReturnable: true,
    },
    {
      id: 2,
      product_photo:
        "https://images.unsplash.com/photo-1572307480813-ceb0e59d8325?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=320&q=80",
      brand_name: "",
      product_name: "Neat hat",
      color: "Brown",
      price: "75,00",
      size: "L",
      reason: "",
      path: "javascript:void(0)",
      isReturnable: true
    },
    {
      id: 3,
      product_photo:
        "https://images.unsplash.com/photo-1594032194509-0056023973b2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=320&q=80",
      brand_name: "",
      product_name: "Asos T-Shirts",
      color: "Black",
      price: "65,00",
      size: "XL",
      reason: "",
      path: "javascript:void(0)",
      isReturnable: false
    },
  ]);

  const fetchData = async () => {
    const response = await callAPI({url:
      "https://returns.narvar.com/returns/specialized/order/ES-0097479850?bzip=null&return_override_code=null&email=carl@itgoesforward.com&phone=null&version=3&gift=false&locale=en_US&product=returns&skip_login=false&timestamp=1702391278666&mockoms=null",
        method: "GET", // or 'PUT'
      }
    );
    console.log(response.result.data);
    return response.result.data;
  };

  const [selectedProducts, setSelectProducts] = useState([]);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentProduct, setCurrentProduct] = useState("");
  console.log(products);
  const selectProduct = (selected, item) => {
    setCurrentProduct(item);
    console.log(selectedProducts.find((p) => p === item));
    console.log(selectedProducts);

    selectedProducts.find((p) => p.id === item.id)
      ? setSelectProducts(selectedProducts.filter((p) => p.id !== item.id))
      : setSelectProducts([...selectedProducts, item]);
    if (!selected && !selectedProducts.find((p) => p === item)?.reason) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    const initData = async () => {
      let tempProducts = [];
      fetchData().then((data) => {
        data.order_info_items.forEach((product) => {
          console.log(product.item_name)
          tempProducts.push({product_name: product.item_name, price: product.item_price, isReturnable: true, product_photo: product.product_image_url, size: product.item_desc, color: product.ColourDescription, id: product.item_id, reason: '', path: "javascript:void(0)"})
        });
        console.log(tempProducts);
        setProducts(tempProducts);
        setLoading(false);

      });
    }
 
    initData();
  }, []);

  return (
    <>
      <Modal
        show={showModal}
        setShow={setShowModal}
        item={currentProduct}
        changeReturnReason={(id) =>
          setProducts(
            products.map((el, i) => {
              if (el.id === currentProduct?.id) {
                console.log("ID", id);
                console.log("set returnreason to:" + returnReasons[id]);
                return { ...el, reason: id };
              }
              console.log("not set", currentProduct);

              return el;
            })
          )
        }
      />

      <section className="py-6 lg:py-10">
        <div className="max-w-screen-lg mx-auto px-4 md:px-8">
          <div className="my-4 lg:mt-12 space-y-3">
          {loading ? <Spinner /> : products.map((item, i) => (
              <Product i={i} item={item} onSelect={selectProduct} />
            ))}
          </div>
        </div>
      </section>
      <button
        type="submit"
        className={`py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-700 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm -dark:focus:ring-offset-gray-800 ${selectedProducts.length === 0 && "bg-grey-200"}}`}
        onClick={() => navigate("/4")}
        disabled={selectedProducts.length === 0}
      >
        Return {selectedProducts.length} items
      </button>
    </>
  );
};
const Product = ({ i, item, onSelect }) => {
  const [selected, setSelected] = useState(false);
  const select = () => {
    onSelect(selected, item);
    setSelected(!selected);
  };

  return (
    <div
      key={i}
      className={`relative px-4 py-5 duration-150 rounded-xl hover:bg-gray-100 transition ease-in duration-200 ${
        selected
          ? "border border-green-700 bg-gray-100 hover:border-green-400 "
          : "border border-gray-200"
      }`}
      onClick={() => item.isReturnable && select()}
    >
      <span
        className={`absolute top-0 right-0 rounded-tr-xl rounded-bl-xl text-xs font-medium bg-green-800 text-white py-1.5 px-3 -dark:bg-gray-900 ${
          selected ? "opacity-100" : "opacity-0"
        }`}
      >
        <FontAwesomeIcon icon={faCheck} />
      </span>
      <a href={item.path} className="space-y-3">
        <div className="flex gap-x-5">
          <div className="rounded-full flex items-center justify-center">
            <img
              class="flex-shrink-0 w-[8.5em] rounded-md"
              src={item.product_photo}
              alt=""
            />
          </div>
          <div className="w-full">
            <h3 className="block lg:text-xl font-bold text-gray-800 mb-1">
              {item.product_name}
            </h3>
            <div className="w-full text-gray-400 sm:text-xs">
              <p>{item.color}</p>
              {item?.size && <p>Size: {item.size}</p>}
              <p>
                Price: <span class="text-sm">€</span>
                {item.price}
              </p>
              {item.reason !== null && (
                <p>
                  Reason: {returnReasons[item.reason]}
                  {item.reason !== "" && (
                    <a className="text-sm" href="">
                      (change)
                    </a>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="text-sm text-gray-600 flex items-center">
          <a class="block h-full pt-6" href="#">
            {item.isReturnable ? <span class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-green-100 text-green-800 -dark:bg-green-900 -dark:text-green-200">
              <FontAwesomeIcon icon={faCheckCircle} />
              Available for return
            </span> : 
            <span class="inline-flex items-center gap-1.5 py-0.5 px-2 rounded-full text-xs font-medium bg-red-100 text-red-800 -dark:bg-red-900 -dark:text-red-200">
              <FontAwesomeIcon icon={faCheckCircle} />
              Not available for return
            </span>}
          </a>
        </div>
      </a>
    </div>
  );
};
