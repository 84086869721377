import "../App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBox,
  faBoxes,
  faBoxesAlt,
  faBoxesPacking,
  faCircleDollarToSlot,
  faFunnelDollar,
  faHandHoldingDollar,
  faHandshakeAlt,
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
  faVanShuttle,
} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "../components/Breadcrumb";
import { IconInput } from "../IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDropbox } from "@fortawesome/free-brands-svg-icons";
import { useEffect, useState } from "react";
import { Spinner } from "../components/Spinner";
import { barcode, getLabel, label } from "../components/getLabel";
import JsBarcode from "jsbarcode";
import ReactBarcode, { Renderer } from "react-jsbarcode";
import { Steps } from "../components/Steps";
import("preline");
//https://www.tailwind-kit.com/
//preline

function ShowShippingLabel() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [label, setLabel] = useState("");

  useEffect(() => {
    const init = async () => {
      setLabel(await getLabel());
    };
    init();
    
  }, []);

  
  const carlCode = '3SMKPL096352571';

    // JsBarcode("#code-39", carlCode, {format: "code39"})
    // JsBarcode("#code128", carlCode, {format: "code39"})
    // JsBarcode("#upc-a", carlCode, {format: "upc"});

    
// DHL EU PARCEL UserId

// e1b615da-7786-4de0-a7a3-98bb88028a3f

// Key

// d686833a-cbae-4022-a52d-2ae0d481aab1


  return (
    <Layout>
      {/* <Breadcrumb/> */}

      {/* <!-- Product info --> */}
      <div className="max-w-[85rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
        <div className="max-w-4xl mx-auto">
          <div className="p-4 sm:p-7">

          <Steps current={4} />

            <div className="lg:col-start-2 md:p-20">
              <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                Scan the digital shipping label
              </h2>
              <p className="my-4 text-gray-500 text-l">
                This is a paperless return. Show this code at a PostNL <a href="#">package point</a>. This barcode is valid for 6 hours.
              </p>

              <ReactBarcode className="mx-auto" value={carlCode} renderer={"image"} options={{height: '80'}}/>
                
              <p className="mt-4 mx-auto">
                Is scanning not possible? Please use this code when handing in your package:
                <pre>{carlCode}</pre>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ShowShippingLabel;
