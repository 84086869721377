import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
const apikey = '79f383deb270bcd8ec1a92f0455140c3';
const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};
 
//https://blog.logrocket.com/create-drag-and-drop-component-react-dropzone/
function DropBox({ onDrop }) {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop,
    noClick: true,
    noKeyboard: true,
  });
  const [imageSent, setImageSent] = useState([]);

  const lists = acceptedFiles.map((list) => (
    <li key={list.path}>
      {list.path} - {list.size} bytes
    </li>
  ));
  const uploadModalRef = useRef();
const uploadRef = useRef();
const progressRef = useRef();
  const uploadFiles = () => {
    uploadModalRef.current.style.display = 'block';
    uploadRef.current.innerHTML = 'File(s) Uploading...';
    const formData = new FormData();
    console.log(imageSent);
    formData.append('image', imageSent);
    formData.append('key', apikey);
    fetch('https://api.imgbb.com/1/upload', { method: "POST", 
    body: formData}).then((response) => {
      console.log(response);
      uploadModalRef.current.style.display = 'none';

    });
  };
  const handleFile = (e) => {
    setImageSent(e.target.files[0]);
  };
  const handleFiles = (files) => {
  }
  const closeUploadModal = () => {
    uploadModalRef.current.style.display = 'none';
}
  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
        handleFiles(files);
    }
}

const validateFile = (file) => {
  const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'];
  if (validTypes.indexOf(file.type) === -1) {
      return false;
  }
  return true;
}
  return (
    <>
      {' '}
      <div style={{'display': 'none'}} className="upload-modal my-10" ref={uploadModalRef}>
    <div className="overlay"></div>
    <div className="close" onClick={(() => closeUploadModal())}>X</div>
    <div className="progress-container">
        <span ref={uploadRef}></span>
        <div className="progress">
            <div className="progress-bar" ref={progressRef}></div>
        </div>
    </div>
</div>
      <section className="dropbox my-10">
        <div style={{"borderColor": `${(props) => getColor(props)}`,"flex":"1","display":"flex","flexDirection":"column","alignItems":"center","padding":"40px","borderWidth":"2px","borderRadius":"10px","borderStyle":"dashed","backgroundColor":"#fafafa","color":"black","fontWeight":"bold","fontSize":"1.4rem","outline":"none","transition":"border 0.24s ease-in-out"}}
          className="dropbox"
          {...getRootProps({ isDragAccept, isFocused, isDragReject })}
        >
  <input
       {...getInputProps({
        onChange: handleFile,
    })}
  />          <p>Add photos here</p>
          <button type="button" className="btn" onClick={open}>
            Click to select file
          </button>

        </div>
      </section>
      {/* <aside>
        <h4>List</h4>
        <p>{lists}</p>
      </aside> */}
                {/* <button className="upload-btn" onClick={() => uploadFiles()}>Upload Images</button> */}

    </>
  );
}
export default DropBox;