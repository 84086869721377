import "../App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { IconInput } from "../IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { Steps } from "../components/Steps";
import("preline");
//https://www.tailwind-kit.com/
//preline

function ChooseReturnMethod() {
  const navigate = useNavigate();
  return (
    <Layout>
      {/* <Breadcrumb/> */}

      {/* <!-- Product info --> */}
      <div class="max-w-[85rem] px-4 py-6 lg:py-10 lg:px-8 lg:py-14 mx-auto">
        <div class="max-w-4xl mx-auto">
          <div class="sm:p-7">
        <Steps current={2} />

            <div class="max-w-[85rem] px-4 py-4 lg:py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
              <div class="mx-auto max-w-2xl mb-6 lg:mb-14 text-center">
                <h2 class="text-xl lg:text-4xl text-gray-800 font-bold -dark:text-gray-200">
                  Choose your return method
                </h2>
              </div>

              <div class="relative">
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8">
                  <div>
                    <div class="lg:h-[550px] shadow-xl shadow-gray-200 p-5 relative z-10 bg-white border border-green-500 rounded-xl md:p-10 -dark:bg-slate-900 -dark:border-gray-700 -dark:shadow-gray-900/[.2]">
                      <h3 class="text-xl mt-2 mb-2 font-bold text-gray-800 -dark:text-gray-200">
                      Return Article Forwarding
                      </h3>
                      <div class="text-sm text-gray-500">
                       Redirect your product <strong>directly to the next customer</strong>.
                      </div>
                      <span class="absolute top-0 right-0 rounded-tr-xl rounded-bl-xl text-xs font-medium bg-green-800 text-white py-1.5 px-3 -dark:bg-gray-900">
                        Most popular
                      </span>

                      <ul
                        role="list"
                        class="mt-4 lg:mt-8 space-y-2 text-sm sm:text-base"
                      >
                        <li class="flex space-x-3">
                          <svg
                            class="flex-shrink-0 h-5 w-5 text-green-500"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="18"
                              height="18"
                              rx="9"
                              fill="currentColor"
                              fill-opacity="0.1"
                            />
                            <path
                              d="M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span class="text-gray-800 -dark:text-gray-200">
                          Free method
                          </span>
                        </li>

                        <li class="flex space-x-3">
                          <svg
                            class="flex-shrink-0 h-5 w-5 text-green-500"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="18"
                              height="18"
                              rx="9"
                              fill="currentColor"
                              fill-opacity="0.1"
                            />
                            <path
                              d="M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span class="text-gray-800 -dark:text-gray-200">
                          Help us save CO<sub>2</sub> emissions, costs and waste.
                          </span>
                        </li>

                        <li class="flex space-x-3">
                          <svg
                            class="flex-shrink-0 h-5 w-5 text-green-500"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="18"
                              height="18"
                              rx="9"
                              fill="currentColor"
                              fill-opacity="0.1"
                            />
                            <path
                              d="M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span class="text-gray-800 -dark:text-gray-200">
                            {/* Just hold on the product for up to <strong>5 days</strong>, before your receiving your shipping label */}
                            Same process as a traditional return, hold on the product for up to <strong>5 days</strong>. 
                          </span>
                        </li>
                        {/* <li class="flex space-x-3">
                          <svg
                            class="flex-shrink-0 h-5 w-5 text-green-500"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="18"
                              height="18"
                              rx="9"
                              fill="currentColor"
                              fill-opacity="0.1"
                            />
                            <path
                              d="M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span class="text-gray-800 -dark:text-gray-200">
                            NOTE: Products must be unworn (fitting allowed), unused and unwashed with all tags attached.
                          </span>
                        </li> */}
                      </ul>

                      <div class="mt-6 lg:mt-10 first:pt-0 last:pb-0">
                        <div class="flex justify-center">
                          <button
                            type="button"
                            class="w-[220px] lg:-translate-x-1/2 lg:absolute lg:bottom-4 lg:left-1/2 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-700 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm -dark:focus:ring-offset-gray-800"
                            onClick={() => navigate("/6")}
                          >
                            Return within 5 days
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="inline-flex lg:hidden items-center justify-center w-full ">
    <hr class="w-64 h-px my-2 bg-gray-200 border-0 -dark:bg-gray-700" />
    <span class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 -dark:text-white -dark:bg-gray-900">OR</span>
</div>
                  <div>
                    <div class="lg:h-[550px] p-4 relative z-10 bg-white border rounded-xl md:p-10 border-gray-300 -dark:bg-slate-900 -dark:border-gray-700">
                      <h3 class="text-xl my-2 font-bold text-gray-800 -dark:text-gray-200">
                        Traditional Return
                      </h3>
                      <div class="text-sm text-gray-500">
                        The product returns to our warehouse.
                      </div>

                      <ul
                        role="list"
                        class="mt-4 lg:mt-8 space-y-2 text-sm sm:text-base"
                      >
                        <li class="flex space-x-3">
                          <svg
                            class="flex-shrink-0 h-5 w-5 text-gray-500"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="18"
                              height="18"
                              rx="9"
                              fill="currentColor"
                              fill-opacity="0.1"
                            />
                            <path
                              d="M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span class="text-gray-800 -dark:text-gray-200">
                            Get a shipping label right away
                          </span>
                        </li>

                        <li class="flex space-x-3">
                          <svg
                            class="flex-shrink-0 h-5 w-5 text-gray-500"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="18"
                              height="18"
                              rx="9"
                              fill="currentColor"
                              fill-opacity="0.1"
                            />
                            <path
                              d="M12.0603 5.78792C12.2511 5.56349 12.5876 5.5362 12.8121 5.72697C13.0365 5.91774 13.0638 6.25432 12.873 6.47875L8.3397 11.8121C8.14594 12.04 7.80261 12.064 7.57901 11.8653L5.17901 9.73195C4.95886 9.53626 4.93903 9.19915 5.13472 8.979C5.33041 8.75885 5.66751 8.73902 5.88766 8.93471L7.88011 10.7058L12.0603 5.78792Z"
                              fill="currentColor"
                            />
                          </svg>
                          <span class="text-gray-800 -dark:text-gray-200">
                            We will deduct the shipping fee (€ 2,95) from your refund
                          </span>
                        </li>
                      </ul>

                      <div class="mt-6 lg:mt-10  first:pt-0 last:pb-0">
                        <div class="flex justify-center">
                          <a
                            type="button"
                            class="w-[220px] lg:-translate-x-1/2 lg:absolute lg:bottom-4 lg:left-1/2 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm shadow-gray-200 align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-green-600 transition-all text-sm border-gray-300 -dark:bg-slate-900 -dark:hover:bg-slate-800 -dark:border-gray-700 -dark:text-gray-400 -dark:hover:text-white -dark:focus:ring-offset-gray-800"
                            href={"https://returns.narvar.com/specialized/returns?locale=es_ES"}
                          >
                            Return now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="hidden md:block absolute top-0 right-0 translate-y-16 translate-x-16">
                  <svg
                    class="w-16 h-auto text-orange-500"
                    width="121"
                    height="135"
                    viewBox="0 0 121 135"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
                      stroke="currentColor"
                      stroke-width="10"
                      stroke-linecap="round"
                    />
                    <path
                      d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
                      stroke="currentColor"
                      stroke-width="10"
                      stroke-linecap="round"
                    />
                    <path
                      d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
                      stroke="currentColor"
                      stroke-width="10"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>

                <div class="hidden md:block absolute bottom-0 left-0 translate-y-16 -translate-x-16">
                  <svg
                    class="w-56 h-auto text-cyan-500"
                    width="347"
                    height="188"
                    viewBox="0 0 347 188"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426"
                      stroke="currentColor"
                      stroke-width="7"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>

              <div class="mt-7 text-center">
                <p class="text-xs text-gray-400">More information about <a href="#" className="font-bold">Return Article Forwarding</a>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ChooseReturnMethod;
