export async function sendMail(data) {
    try {
      const response = await fetch('http://localhost:8000/api/sendMail', {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    }
  }