import { useEffect, useId, useRef } from "react";

function SessionTakeOver() {
let url = "https://returns.narvar.com/specialized/returns?locale=es_ES";
const returnid = "ES-0097479850";
const email = "carl@itgoesforward.com";

const iFrameID = useId();
const iFrameRef = useRef(null);
useEffect(() => {
    loadIframe();   
},[]);

function loadIframe(){
    //pre-authenticate
    var req = new XMLHttpRequest();
    req.open("GET",url); //use POST to safely send combination

    //setiFrame's SRC attribute
    iFrameRef.src = url;


var innerDoc = iFrameRef.contentWindow.document.body.innerHTML; 

innerDoc.querySelector('input[data-test=input-number]').value = returnid;
innerDoc.querySelector('input[data-test=input-email]').value = email;

innerDoc.querySelector('input[data-test=return-button-next]').click();
}


// async function run() {

// const puppeteer = require('puppeteer');

// const browser = await puppeteer.launch({
//     headless: false
// });
// const page = await browser.newPage();
// console.log("opening page");
// await page.goto(url);
// console.log("page opened");
// await page.type('input[data-test=input-number]', returnid);

// await page.type('input[data-test=input-email]', email);
// console.log("email entered");
// await page.evaluate(() => {
//     document.querySelector('input[data-test=return-button-next]').click();
// });
// console.log("email entered");

// // //await browser.close();
// }
// // 
// run();

<>
<iframe id={iFrameRef} style="display: none;"></iframe>




</>
}





export default SessionTakeOver;
