import "../App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "../components/Breadcrumb";
import { IconInput } from "../IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { Steps } from "../components/Steps";
import { Modal } from "../components/Modal";
import { useState } from "react";
import { ShipmentModal } from "../components/ShipmentModal";
import { sendMail } from "../components/Sendmail";

import("preline");
//https://www.tailwind-kit.com/
//https://tailwind-elements.com/
//www.floatui.com
//preline

function ReceivingShipment() {
  const [showModal, setShowModal] = useState(false);
  sendMail({template: 'match-made', to: 'pepijn+sender@itgoesforward.com'})

  const navigate = useNavigate();
  return (
    <Layout>
            <ShipmentModal
        show={showModal}
        setShow={setShowModal}
        item={'test'}
        changeReturnReason={(id) =>
         {}
        }
      />
      {/* <Breadcrumb/> */}

      {/* <!-- Product info --> */}
      <div class="max-w-[85rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
        <div class="max-w-4xl mx-auto">
          <div class="p-4 sm:p-7">

            <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-2 mx-auto">
              <div class="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-12 lg:text-left">
                <h2 class="text-2xl font-bold tracking-tight sm:text-4xl">
                 This is the receiver flow
                </h2>
                <p class="my-6 text-lg leading-8 text-gray-700">
email flow                </p>

<a
                    href="#"
                    class="text-sm font-semibold leading-6"
                    onClick={() =>   sendMail({template: 'package-received', to: 'pepijn+receiver@itgoesforward.com'})}
                  >
                    Package delivered <span aria-hidden="true">→</span>
                  </a>

              </div>

              <div class="mt-7 text-center">
                <p class="text-xs text-gray-400">&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ReceivingShipment;
