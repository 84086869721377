import igf_logo from "./assets/img/igf-logo.png"

const backgroundImage = "https://assets.narvar.com/retailers/specialized/02_ReturnsPortalAsset_2000x1400.jpg?1692704109"

export const Layout = ({ bgUrl = null, children }) => (
  <>
  <div class="w-screen min-h-screen flex item-center justify-center p-4 bg-slate-200 pt-10" style={{backgroundImage: `url('${backgroundImage}')`, backgroundSize: "cover", backgroundPosition: "top"}}>
    <div class="bg-white max-w-4xl w-full lg:h-max rounded-md shadow-lg border border-gray-200">{children}</div>
  </div>
  <div class="bg-white/75 fixed bottom-0 right-0 p-2 m-2 item-center   rounded-md shadow-lg border border-gray-200"><p className="text-[9px]/[12px] tracking-wider text-center text-color-gray "><img className="m-auto mb-1" width={45} src={igf_logo} />powered by</p><p className="text-[9.5px]/[12px] tracking-tight text-center">itgoesforward</p></div>
  </>
);
