import "./App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { IconInput } from "./IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "./Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "./assets/img/specialized-1.png";

//https://www.tailwind-kit.com/
//preline


//const clientLogo =  "https://returnsapi.bleckmann.com/images/GYMCOFFEE/logo/logo.png?dummy=1692787493"



function Login() {
  const navigate = useNavigate();
  return (
    <Layout>
   
            {/* <Breadcrumb/> */}

            {/* <!-- Product info --> */}
            <div class="max-w-[40rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
              <div class="max-w-4xl mx-auto">
              <img src={logo} className="m-auto mb-1" width={200} />

                <div class="p-4 sm:p-7">
                  <div class="text-center">
                    <h1 class="text-3xl lg:text-4xl text-gray-800 font-bold -dark:text-gray-200">
                      Return Portal
                    </h1>

 
                    <p class="mt-5 text-sm text-gray-600 -dark:text-gray-400">
                      Welcome, please enter the data provided with your purchase.
                    </p>
                  </div>

                  <div class="mt-10">
                    {/* <div class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:mr-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ml-6 -dark:text-gray-500 -dark:before:border-gray-600 -dark:after:border-gray-600">Please enter your login details</div> */}

                    <form>
                      <div class="grid gap-y-4">
                      {/* <IconInput icon={faListOl} name="orderNumber"  label="Order number" />
                      <IconInput icon={faContactBook} name="emailOrPhone"  label="Email or Phone"/> */}
                      <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 -dark:text-white">Ordernumber</label>
<div class="relative mb-0">
  <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
     <FontAwesomeIcon className="text-gray-500 -dark:text-gray-400" icon={faListOl} />
  </div>
  <input type="text" name="orderNumber" id="input-group-1" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  -dark:bg-gray-700 -dark:border-gray-600 -dark:placeholder-gray-400 -dark:text-white -dark:focus:ring-blue-500 -dark:focus:border-blue-500" placeholder="Order Number" />
</div>
       
                      <label for="input-group-2" class="block mb-2 text-sm font-medium text-gray-900 -dark:text-white">Email or Phone</label>
<div class="relative mb-6">
  <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
     <FontAwesomeIcon className="text-gray-500 -dark:text-gray-400" icon={faContactBook} />
  </div>
  <input type="text" name="emailOrPhone" id="input-group-2" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  -dark:bg-gray-700 -dark:border-gray-600 -dark:placeholder-gray-400 -dark:text-white -dark:focus:ring-blue-500 -dark:focus:border-blue-500" placeholder="Email or Phone" />
</div>
                        <div class="space-y-20"></div>
                        <div class="space-y-10"></div>
                        <button
                          type="submit"
                          class="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-700 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm -dark:focus:ring-offset-gray-800"
                          onClick={() => navigate("/2")}
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
  
    </Layout>
  );
}

export default Login;

