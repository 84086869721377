import "../App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "../components/Breadcrumb";
import { IconInput } from "../IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { Steps } from "../components/Steps";
import("preline");
//https://www.tailwind-kit.com/
//preline

function TraditionalReturnInstructions() {
  const navigate = useNavigate();
  return (
    <Layout>
      {/* <Breadcrumb/> */}

      {/* <!-- Product info --> */}
      <div class="max-w-[85rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
        <div class="max-w-4xl mx-auto">
          <Steps current={3} />
          <div class="p-4 sm:p-7">
          <div class="text-center pt-5">
            <h2 class="text-3xl lg:text-4xl text-gray-800 font-bold -dark:text-gray-200">
            Pack your return and ship the item.
                </h2>
                <p class="mt-6 text-lg leading-8 text-gray-700 leading-relaxed">Bring the package to the nearest <a href="#">postal point</a> that accepts digital labels. In our shipping instructions you find more information. </p>

            </div>

            <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">





        <div class="mx-auto flex items-center justify-center gap-x-20 ">
          <a href="#"onClick={() => navigate("/9")}  class="rounded-md transition ease-in duration-200 bg-green-700 text-white px-4 py-3 text-sm font-semibold text-gray-900 shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Create digital shipping label</a>
          <a href="#" class="text-sm font-semibold leading-6" onClick={() => navigate("/7")}>Shipping instructions <span aria-hidden="true">→</span></a>
        </div>




              <div class="mt-7 text-center">
                <p class="text-xs text-gray-400">&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TraditionalReturnInstructions;
