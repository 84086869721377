import { useState } from "react"

export const Steps = ({step = 3, current = 1}) => {

    const [steps, setStep] = useState({
        stepsCount: step,
        currentStep: current
    })

    return (
        <div className="max-w-lg mx-auto px-4 sm:px-0">
            <ul aria-label="Steps" className="flex items-center">
                {[...Array(steps.stepsCount)].map((item, i) => (
                    <li aria-current={steps.currentStep == i + 1 ? "step" : false} className="flex-1 last:flex-none flex items-center">
                        <div className={`w-8 h-8 rounded-full border-2 flex-none flex items-center justify-center ${steps.currentStep > i + 1 ? "bg-green-700 border-green-700" : "" || steps.currentStep == i + 1 ? "border-green-700" : ""}`}>
                            <span className={`w-2.5 h-2.5 rounded-full bg-green-700 ${steps.currentStep != i + 1 ? "hidden" : ""}`}></span>
                            {
                                steps.currentStep > i + 1 ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                    </svg>
                                ) : ""
                            }
                        </div>
                        <hr className={`w-full border ${i + 1 == steps.stepsCount ? "hidden" : "" || steps.currentStep > i + 1 ? "border-green-700" : ""}`} />
                    </li>
                ))}
            </ul>
        </div>
    )
}