import "../App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "../components/Breadcrumb";
import { IconInput } from "../IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { Steps } from "../components/Steps";
import { sendMail } from "../components/Sendmail";
import("preline");
//https://www.tailwind-kit.com/
//https://tailwind-elements.com/
//www.floatui.com
//preline

function StartHandinPeriod() {
  const navigate = useNavigate();

  
sendMail({template: 'return-registered', to: "pepijn+sender@itgoesforward.com"})


  return (
    <Layout>
      {/* <Breadcrumb/> */}

      {/* <!-- Product info --> */}
      <div class="max-w-[85rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
        <div class="max-w-4xl mx-auto">
          <div class="p-4 sm:p-7">
           <Steps current={3} />

            <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-2 mx-auto">

              <div class="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-12 lg:text-left ">
                <h2 class="text-2xl font-bold tracking-tight sm:text-4xl">
                  Your return has been registered.
            
                  <span class="block text-green-700">
                    Thanks for helping us reduce waste and CO₂ emissions.
                  </span>
                </h2>
                <p class="my-6 text-md mt-6 mx-auto text-gray-400">
                Your return number is #10000123321
                </p>
                <p class="my-6 text-lg leading-6 text-gray-700 text-sm">

                  Please<strong> wait up to 5 days</strong> until we find another customer who
                  bought your unused product. Within 5 days you receive an email asking to drop off your shipment at a postal office, DHL service point or DHL parcel station.
                </p>

                <p class="my-6 text-lg leading-6 text-gray-700 text-sm">You do not have to do anything now, just wait.</p>

                <div class="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                  <a href="#"     onClick={() => navigate("/8")} class="text-xs leading-6">
                    More information about this process &gt;&gt;
                  </a>
               
                </div>
                <a href="#"     onClick={() => navigate("")} class="text-xs leading-6 text-red-500">
                    Cancel my return
                  </a>
              </div>

              <div class="mt-7 text-center">
                <p class="text-xs text-gray-400">&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default StartHandinPeriod;
