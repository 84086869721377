import "../App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "../components/Breadcrumb";
import { IconInput } from "../IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { ProductTable } from "../components/ProductTable";
import { Steps } from "../components/Steps";
import { Products } from "../components/Products";
import("preline");
//https://www.tailwind-kit.com/
//preline

function PickProduct() {
  const navigate = useNavigate();
  const d = new Date();
  d.setDate(d.getDate() + 14);
  const date = d.toLocaleDateString("en-GB", {
      weekday: "long",
      month: "long",
      day: "numeric",
  });
  // Thu Mar 01 2012
  return (
    <Layout>
      {/* <Breadcrumb/> */}

      {/* <!-- Product info --> */}
      <div class="max-w-[80rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
        <div class="mx-auto">
          <Steps current={1} />

          <div class="p-4 sm:p-7">
            <div class="text-center pt-2 lg:pt-5">
            <h2 class="text-xl lg:text-4xl text-gray-800 font-bold -dark:text-gray-200">
                  What would you like to return or exchange?
                </h2>
               <p className="text-sm text-gray-600 mt-2">
                The items from order #3434 can be returned until {date}
              </p> 
            </div>



            <Products />

          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PickProduct;
