import "../App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "../components/Breadcrumb";
import { IconInput } from "../IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { Steps } from "../components/Steps";
import { Modal } from "./../components/Modal";
import { useState } from "react";
import { ShipmentModal } from "../components/ShipmentModal";

import("preline");
//https://www.tailwind-kit.com/
//https://tailwind-elements.com/
//https://www.flowbite.com
//www.floatui.com
//preline

function StartShipment() {
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  return (
    <Layout>
            <ShipmentModal
        show={showModal}
        setShow={setShowModal}
        item={'test'}
        changeReturnReason={(id) =>
         {}
        }
      />
      {/* <Breadcrumb/> */}

      {/* <!-- Product info --> */}
      <div class="max-w-[85rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
        <div class="max-w-4xl mx-auto">
          <div class="p-4 sm:p-7">
            <Steps current={3} />

            <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-2 mx-auto">
              <div class="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-12 lg:text-left">
                <h2 class="text-2xl font-bold tracking-tight sm:text-4xl">
                  You can now return your package.
                </h2>
                <p class="my-6 text-lg leading-8 text-gray-700">
                  We will route your package directly to another customer.
                </p>

                <p class="mt-6 text-md leading-8 text-gray-700 leading-relaxed">
                  Bring the package to the nearest <a href="https://www.postnl.nl/en/locationfinder/" target="_blank" className="font-bold">postal point</a>. 
This is a digital label, which can be scanned from your phone. Please generate the label when you are about to send it. 
</p>

                <div class="mx-auto mt-10 mb-10 flex items-center justify-center gap-x-10 ">
                  <a
                    href="#"
                    onClick={() => setShowModal(true)}
                    class="rounded-md transition ease-in duration-200 bg-green-700 text-white px-4 py-3 text-sm font-semibold text-gray-900 shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                  >
                    Generate digital shipping label
                  </a>
                  <a
                    href="#"
                    class="text-sm font-semibold leading-6"
                    onClick={() => navigate("/8")}
                  >
                    Shipping instructions <span aria-hidden="true">→</span>
                  </a>
                </div> 
              </div>

              <div class="mt-7 text-center">
                <p class="text-xs text-gray-400">&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default StartShipment;
