import { useEffect, useState } from "react";

export const SelectList = ({options, selectedKey, onSelect}) => {
  const [selected, setSelected] = useState(selectedKey); 
  const select = (val) => {console.log('VAL', val); setSelected(val); onSelect(val)}

  // useEffect(() => {
  //   select(selectedKey)
  // }, [])

  return <div class="w-full flex flex-col pt-3 px-4">
    {/* <select className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-green-600 leading-relaxed" onChange={e => select(e.target.value)}> */}
      {options.map((item, i) =>
       <a className={`inline-flex items-center gap-x-3.5 py-3 px-4 text-sm hover:font-medium border text-grey-500 -mt-px rounded-t-lg mt-2 rounded-b-lg hover:z-10 hover:outline-none hover:ring-2 hover:ring-green-600 -dark:border-gray-700 ${selected === i && 'font-medium ring-green-600 ring-2 z-10' }`} onClick={() => select(i)}>
      {item}
</a>)}
  </div>
;
};
