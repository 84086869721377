import { faArrowRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Dropdown } from "./Dropdown";
import { returnReasons } from "./Products";
import { SelectList } from "./SelectList";

export const Modal = ({ show = false, setShow, item, changeReturnReason }) => {
  const [clicked, setClicked] = useState(false);
  const [screen, setScreen] = useState(0);
  const [reason, setReason] = useState(item?.reason);
  const [selectedSize, setSelectedSize] = useState("");

  return show ? (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div
        className="fixed inset-0 w-full h-full bg-black opacity-40"
        onClick={() => setShow(false)}
      ></div>
      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
          {screen === 0 && (
            <>
              <div className="mt-3">
                <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                  <FontAwesomeIcon
                    icon={faArrowRotateLeft}
                    className="text-green-600"
                  />
                </div>
                <div className="mt-2 text-center">
                  <h4 className="text-lg font-medium text-gray-800">
                    Why are you returning your item?
                  </h4>
                  <p className="mt-2 text-[14px] leading-relaxed text-gray-500">
                    This will help us in handling your return better.
                  </p>
                </div>
              </div>
              <div className="max-w items-center mb-5 flex justify-center">
                <SelectList
                  options={returnReasons}
                  selectedKey={reason}
                  onSelect={(s) => {
                    setReason(s);
                    return changeReturnReason(s);
                  }}
                />
              </div>
              <div className="items-center mt-3 sm:flex">
                <button
                  className="max-w mt-2 p-2.5 flex-1 text-white bg-green-700 rounded-md outline-none ring-offset-2 ring-green-700 focus:ring-2"
                  onClick={() => {
                    return setScreen(1);
                    //return setShow(false);
                  }}
                >
                  {reason < 5 ? "Next" : "Done"}
                </button>
                {/* <button className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-green-700 focus:ring-2"
                                onClick={() => setShow(false)}
                            >
                                Undo
                            </button> */}
              </div>
            </>
          )}
          {screen === 1 && (
            <>             <p>You indicated that <strong>{item?.product_name}</strong> size <strong>L</strong> doesn't fit.</p> 

            <p>We can send the correct size. Please select the option below.</p>
              <div className="max-w items-center mb-2 flex justify-center">
                <Dropdown
                  options={["S", "M", "XL", "XXL" ]} // Add your size options here
                  selectedOption={selectedSize}
                  onSelect={(size) => setSelectedSize(size)}
                />
              </div>
              <div className="items-center mt-3 sm:flex">
                <button
                  className="max-w mt-2 p-2.5 mr-1 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-green-700 focus:ring-2"
                  onClick={() => {
                    return setScreen(0);
                    //return setShow(false);
                  }}
                >
                  Back
                </button>
                <button
                  className="w-full mt-2 p-2.5 ml-1 flex-1 text-white bg-green-700 rounded-md outline-none ring-offset-2 ring-green-700 focus:ring-2"
                  onClick={() => setShow(false)}
                >
                  Done
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};
