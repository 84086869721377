import "../App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBox,
  faBoxes,
  faBoxesAlt,
  faBoxesPacking,
  faCircleDollarToSlot,
  faFunnelDollar,
  faHandHoldingDollar,
  faHandshakeAlt,
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
  faVanShuttle,
} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "../components/Breadcrumb";
import { IconInput } from "../IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDropbox } from "@fortawesome/free-brands-svg-icons";
import("preline");
//https://www.tailwind-kit.com/
//preline

function App() {
  const navigate = useNavigate();
  return (
    <Layout>
      {/* <Breadcrumb/> */}

      {/* <!-- Product info --> */}
      <div class="max-w-[85rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
        <div class="max-w-4xl mx-auto">
          <div class="p-4 sm:p-7">
            {/* <div class="text-center">
              <h1 class="block text-2xl font-bold text-gray-800 --dark:text-white">
                Return reaseon
              </h1>
              <p class="mt-2 text-sm text-gray-600 --dark:text-gray-400">
                Your selected item(s):
              </p>
            </div> */}

<div class="lg:col-start-2 md:pl-20">
<h2 class="text-3xl font-bold tracking-tight sm:text-4xl">

Next Steps
</h2>
            <ul class="mt-10">
                <li>
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-green-700 rounded-md">
                                <FontAwesomeIcon icon={faBoxesPacking} />
                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 -dark:text-white">
                               Pack
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-500 -dark:text-gray-300">
                                Neatly repack the item you want to send
                            </p>
                        </div>
                    </div>
                </li>
                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-green-700 rounded-md">
                            <FontAwesomeIcon icon={faVanShuttle} />

                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 -dark:text-white">
                                Hand-in at a drop-off point
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-500 -dark:text-gray-300">
                            Drop-off your package when receiving the email with a shipping QR-code

                            </p>
                        </div>
                    </div>
                </li>
                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-green-700 rounded-md">
                            <FontAwesomeIcon icon={faHandHoldingDollar} />

                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 -dark:text-white">
                                Get reviewed
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-500 -dark:text-gray-300">
                            The receiving customer reviews your shipment. Receiving good reviews lead to rewards

                            </p>
                        </div>
                    </div>
                </li>

                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-green-700 rounded-md">
                            <FontAwesomeIcon icon={faCircleDollarToSlot} />

                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 -dark:text-white">
                            Get refunded within 7 workdays
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-500 -dark:text-gray-300">
                            Get refunded within 7 working days. Two times quicker than the current 14 working days


                            </p>
                        </div>
                    </div>
                </li>

                <li class="mt-10">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <div class="flex items-center justify-center w-12 h-12 text-white bg-green-700 rounded-md">
                            <FontAwesomeIcon icon={faHandshakeAlt} />

                            </div>
                        </div>
                        <div class="ml-4">
                            <h5 class="text-lg font-bold leading-6 text-gray-900 -dark:text-white">
                                Issues?
                            </h5>
                            <p class="mt-2 text-base leading-6 text-gray-500 -dark:text-gray-300">
                            Disagreements between customers are handled and solved by Retailer



                            </p>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default App;
