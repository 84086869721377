import {
    faContactBook,
    faImage,
  } from "@fortawesome/free-regular-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Checked = () => <svg
xmlns="http://www.w3.org/2000/svg"
fill="none"
viewBox="0 0 24 24"
stroke-width="1.5"
stroke="currentColor"
class="w-6 h-6 text-green-500"
>
<path
  stroke-linecap="round"
  stroke-linejoin="round"
  d="M4.5 12.75l6 6 9-13.5"
/>
</svg>;

const Unchecked =  () =>           
<FontAwesomeIcon className="text-slate-500" icon={faImage} />
{/* <svg
xmlns="http://www.w3.org/2000/svg"
fill="none"
viewBox="0 0 24 24"
stroke-width="1.5"
stroke="currentColor"
class="w-6 h-6 text-slate-500 hover:text-indigo-600 hover:cursor-pointer"
>
<path
  stroke-linecap="round"
  stroke-linejoin="round"
  d=" 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
/>
</svg> */}



export const TaskList = ({tasks}) => (
  <div class="max-w-lg mx-auto  bg-white px-3 py-1 rounded-xl shadow shadow-slate-300 my-4">
    <div id="tasks" class="my-2">
      {tasks?.map((task, i) => 
        <div
          id="task"
          className={`text-sm flex justify-between items-center ${i !== (tasks?.length-1) && 'border-b'} border-slate-200 py-3 px-2 border-l-4  border-l-transparent`}
        >
          <div class="inline-flex items-center space-x-2">
            <div>
         {!!task?.done ? <Checked /> : <Unchecked />}
       
    
            </div>
            <div className={task?.done && 'text-slate-500'}>
{task?.name}            </div>
          </div>
        </div>
      )} 
    </div>
  </div>
);
