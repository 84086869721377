import React from "react";
import { CloseButton } from "./CloseButton";
function Image({ image }) {
  return (
    <div className="relative">
        <CloseButton />
      <img alt='' src={image.src} />
    </div>
  );
}
export default Image;