import { faArrowRotateLeft, faEnvelopesBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Dropdown } from "./Dropdown";
import { returnReasons } from "./Products";
import { useNavigate } from "react-router-dom";

export const ShipmentModal = ({
  show = false,
  setShow,
}) => {
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();


  return show ? (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div
        className="fixed inset-0 w-full h-full bg-black opacity-40"
        onClick={() => setShow(false)}
      ></div>
      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
          <div className="mt-3">
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
              <FontAwesomeIcon
                icon={faEnvelopesBulk}
                className="text-green-600"
              />
            </div>
            <div className="mt-2 text-center">
              <h4 className="text-lg font-medium text-gray-800">
                 Are you at or near the postal point? 
              </h4>
              <p className="mt-2 text-[14px] leading-relaxed text-gray-500">
                This will help us in handling your return better.
              </p>
            </div>
          </div>

          <div className="items-center mt-3 sm:flex">
            <button
              className="w-full mt-2 p-2.5 flex-1 text-white bg-green-700 rounded-md outline-none ring-offset-2 ring-green-700 focus:ring-2"
              onClick={() => {
                 navigate("/9");
                return setShow(false);
                
              }}
            >
              Yes, please generate the label
            </button>
            <button className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-green-700 focus:ring-2"
                                onClick={() => setShow(false)}
                            >
                                No, not yet.
                            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};
