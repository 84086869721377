import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Login from "./Login";
import PickProduct from "./pages/PickProduct";
import Step3 from "./Step3";
import ChooseReturnMethod from "./pages/ChooseReturnMethod";
import TraditionalReturnInstructions from "./pages/TraditionalReturnInstructions";
import StartHandinPeriod from "./pages/StartHandinPeriod";
import ShippingInstructions from "./pages/ShippingInstructions";
import ShowShippingLabel from "./pages/ShowShippingLabel";
import reportWebVitals from "./reportWebVitals";
import ShippingMethod from "./pages/ChooseShippingMethod";
import StartShipment from "./pages/StartShipment";
import ReceivingShipment from "./pages/ReceivingShipment";
import SessionTakeOver from "./pages/SessionTakeOver";
import Test2 from "./pages/Test2";

const router = createBrowserRouter([
  {
    path: "/test2",
    element: <Test2 />,
  },
  {
    path: "/test",
    element: <SessionTakeOver />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "2",
    element: <PickProduct />,
  },
  {
    path: "3",
    element: <Step3 />,
  },
  {
    path: "4",
    element: <ChooseReturnMethod />,
  },
  {
    path: "5",
    element: <TraditionalReturnInstructions />,
  },
  {
    path: "6",
    element: <StartHandinPeriod />,
  },
  {
    path: "7",
    element: <ShippingMethod />,
  },
  {
    path: "8",
    element: <ShippingInstructions />,
  },
  {
    path: "9",
    element: <ShowShippingLabel />,
  },
  {
    path: "10",
    element: <StartShipment />,
  },
  {
    path: "11",
    element: <ReceivingShipment />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
