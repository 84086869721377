import { useEffect, useId, useRef } from "react";
import puppeteer from 'puppeteer-web';

function Test2() {
let url = "https://returns.narvar.com/specialized/returns?locale=es_ES";
const returnid = "ES-0097479850";
const email = "carl@itgoesforward.com";



async function run() {

const puppeteer = require('puppeteer-web');

const browser = await puppeteer.launch({
    headless: false
});
const page = await browser.newPage();
console.log("opening page");
await page.goto(url);
console.log("page opened");
await page.type('input[data-test=input-number]', returnid);

await page.type('input[data-test=input-email]', email);
console.log("email entered");
await page.evaluate(() => {
    document.querySelector('input[data-test=return-button-next]').click();
});
console.log("email entered");

// //await browser.close();
}
// 
run();

<>
{/* <iframe id={iFrameRef} style="display: none;"></iframe> */}




</>
}





export default Test2;
