import JsBarcode from "jsbarcode";

const url = "https://api-sandbox.postnl.nl/shipment/v2_2/label";

async function postJSON(data) {
    try {
      const response = await fetch(url, {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          "apikey": "f8eec1be-6aa8-49ea-bbfd-b1a5a6b4405b"
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const data = {
    "Customer": {
      "Address": {
        "AddressType": "02",
        "City": "Den Haag",
        "CompanyName": "PostNL",
        "Countrycode": "NL",
        "HouseNr": "3",
        "Street": "Waldorpstraat",
        "Zipcode": "2521CA"
      },
      "CollectionLocation": "123456",
      "ContactPerson": "Janssen",
      "CustomerCode": "DEVC",
      "CustomerNumber": "11223344",
      "Email": "email@company.com",
      "Name": "Janssen"
    },
    "Message": {
      "MessageID": "1",
      "MessageTimeStamp": "08-09-2022 12:00:00",
      "Printertype": "GraphicFile|JPG 300 dpi"
    },
    "Shipments": [
      {
        "Addresses": [
          {
            "AddressType": "01",
            "City": "Utrecht",
            "Countrycode": "NL",
            "FirstName": "Carl",
            "HouseNr": "9",
            "HouseNrExt": "a bis",
            "Name": "van Heijst",
            "Street": "Bilderdijkstraat",
            "Zipcode": "3532VA"
          }
        ],
        "Barcode": "3SDEVC748859096",
        "Contacts": [
          {
            "ContactType": "01",
            "Email": "receiver@email.com",
            "SMSNr": "+31612345678",
            "TelNr": "+31301234567"
          }
        ],
        "Dimension": {
          "Weight": "2000"
        },
        "ProductCodeDelivery": "3085"
      }
    ]
  };

   const response = await postJSON(data);
   export const getLabel = async () =>  { const ee = await postJSON(data); return ee?.ResponseShipments[0].Labels[0].Content} 
   export const barcode = response?.ResponseShipments[0].Barcode;
   export const label = response?.ResponseShipments[0].Labels[0].Content;
