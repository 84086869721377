import "./App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { IconInput } from "./IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "./Layout";
import retour_img from "./assets/img/foto_retour_v2.jpg";
import { useDropzone } from "react-dropzone";
import { useCallback, useEffect, useState } from "react";
import DropBox from "./components/Dropbox";
import ShowImage from "./components/ShowImage";
import { TaskList } from "./components/TaskList";

//https://www.tailwind-kit.com/
//preline

function App() {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          { id: index, src: e.target.result },
        ]);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);
  return (
    <Layout>
      {/* <!-- Product info --> */}
      <div class="max-w-[40rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
        <div class="max-w-4xl mx-auto">
          <div class="p-4 sm:p-7">
            <div class="text-center">
              <h1 class="block text-2xl font-bold text-gray-800 -dark:text-white">
                Photo verification
              </h1>
              <p class="mt-2 text-sm text-gray-600 -dark:text-gray-400">
                Please provide at least one image showcasing the condition of the
                item(s).
              </p>
            </div>

            <div class="mt-6">
              {images?.length == 0 && <DropBox onDrop={onDrop} />}
              <ShowImage images={images} />
              {/* <div><img src={retour_img} /></div> */}
              {/* <div class="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:mr-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ml-6 -dark:text-gray-500 -dark:before:border-gray-600 -dark:after:border-gray-600">Please enter your login details</div> */}

              <TaskList
                tasks={[
                  {
                    name: "Front of the product with label attached",
                    done: images?.length > 0,
                  },
                  { name: "Back of the product", done: images?.length > 1 },
                  { name: "Product neatly packed", done: images?.length > 2 },
                ]}
              />
              <div class="mt-6 lg:mt-10  first:pt-0 last:pb-0">
                <div class="flex justify-center">
                  <button
                    type="button"
                    class="w-[220px] lg:-translate-x-1/2 lg:absolute lg:bottom-4 lg:left-1/2 py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm shadow-gray-200 align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-green-600 transition-all text-sm border-gray-300 -dark:bg-slate-900 -dark:hover:bg-slate-800 -dark:border-gray-700 -dark:text-gray-400 -dark:hover:text-white -dark:focus:ring-offset-gray-800"
                    onClick={() => navigate("/5")}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default App;
