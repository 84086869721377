import "../App.css";
import {
  faContactBook,
  faEnvelopeOpen,
} from "@fortawesome/free-regular-svg-icons";
import {
  faListNumeric,
  faListOl,
  faReorder,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "../components/Breadcrumb";
import { IconInput } from "../IconInput";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import { ProductTable } from "../components/ProductTable";
import { Steps } from "../components/Steps";
import { Products } from "../components/Products";
import("preline");
//https://www.tailwind-kit.com/
//preline

function ShippingMethod() {
  const navigate = useNavigate();
  return (
    <Layout>
      {/* <Breadcrumb/> */}

      {/* <!-- Product info --> */}
      <div class="max-w-[80rem] px-4 py-10 lg:px-8 lg:py-14 mx-auto">
        <div class="mx-auto">
          <Steps current={1} />

          <div class="p-4 sm:p-7">
            <div class="text-center pt-5">
            <h2 class="text-3xl lg:text-4xl text-gray-800 font-bold --dark:text-gray-200">
Please pick your shipment method                </h2>

            </div>

            <ul class="max-w-sm flex flex-col">
  <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg -dark:bg-gray-800 -dark:border-gray-700 -dark:text-white">
    <div class="relative flex items-start w-full">
      <div class="flex items-center h-5">
        <input id="hs-list-group-item-radio-1" name="hs-list-group-item-radio" type="radio" class="border-gray-200 rounded-full -dark:bg-gray-800 -dark:border-gray-700 -dark:checked:bg-blue-500 -dark:checked:border-blue-500 -dark:focus:ring-offset-gray-800" checked />
      </div>
      <label for="hs-list-group-item-radio-1" class="ml-3 block w-full text-sm text-gray-600 -dark:text-gray-500">
        <img src="https://www.postnl.nl/Images/Beeldmerk-PostNL_tcm10-8609.png" />
      </label>
    </div>
  </li>

  <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg -dark:bg-gray-800 -dark:border-gray-700 -dark:text-white">
    <div class="relative flex items-start w-full">
      <div class="flex items-center h-5">
        <input id="hs-list-group-item-radio-2" name="hs-list-group-item-radio" type="radio" class="border-gray-200 rounded-full -dark:bg-gray-800 -dark:border-gray-700 -dark:checked:bg-blue-500 -dark:checked:border-blue-500 -dark:focus:ring-offset-gray-800" />
      </div>
      <label for="hs-list-group-item-radio-2" class="ml-3 block w-full text-sm text-gray-600 -dark:text-gray-500">
        <img src="https://logodownload.org/wp-content/uploads/2015/12/dhl-logo.png" width={100}/>
      </label>
    </div>
  </li>

  <li class="inline-flex items-center gap-x-2 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg -dark:bg-gray-800 -dark:border-gray-700 -dark:text-white">
    <div class="relative flex items-start w-full">
      <div class="flex items-center h-5">
        <input id="hs-list-group-item-radio-3" name="hs-list-group-item-radio" type="radio" class="border-gray-200 rounded-full -dark:bg-gray-800 -dark:border-gray-700 -dark:checked:bg-blue-500 -dark:checked:border-blue-500 -dark:focus:ring-offset-gray-800" />
      </div>
      <label for="hs-list-group-item-radio-3" class="ml-3 block w-full text-sm text-gray-600 -dark:text-gray-500">
        <img src="https://global-uploads.webflow.com/6130cca73e241afa176ffdd8/6130cca73e241a66b36fff97_Homerr%20logo%20groen%20payoff.png" width={100} />
      </label>
    </div>
  </li>
</ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ShippingMethod;
